@import 'variables';

.secondary-font {
    font-family: $secondaryfont;
}

.text-small {
    font-size: $small;
}

.text-extra-tiny {
    font-size: $extra-tiny;
}

.text-tiny {
    font-size: $tiny;
}

.text-small-plus {
    font-size: $smallplus;
}

.text-regular {
    font-size: $regular;
    letter-spacing: 0.51px;
    color: $lightgray;
}

.text-normal {
    font-size: $regular;
}

.text-regular-plus {
    font-size: $regularplus;
}

.text-medium {
    font-size: $medium;
}

.text-medium-static {
    font-size: $medium;
}

.text-large {
    font-size: $large;
}

.text-large-plus {
    font-size: $largeplus;
}

.text-large-plus-static {
    font-size: $largeplus;
}

.text-extra-large {
    font-size: $extralarge;
}

.text-extra-large-static {
    font-size: $extralarge;
}

.text-huge {
    font-size: $huge;
}

.text-morehuge {
    font-size: $morehuge;
}

.text-extra-huge {
    font-size: $extrahuge;
}

.text-extra-huge-title {
    font-size: $extrahugetitle;
}

.text-extra-huge-plus {
    font-size: $extrahugeplus;
}

.text-extra-extra-huge {
    font-size: $extraextrahuge;
}

.lh-16-41 {
    line-height: 16.41px;
}

:not(.no-font-bp) {
    @media (max-width: $desktop) {
        &.text-extra-huge {
            font-size: $morehuge;
        }

        &.text-extra-large {
            font-size: $large;
        }

        &.text-large-plus {
            font-size: $medium;
        }

        &.text-small {
            font-size: $extrasmall !important;
        }
    }

    @media (max-width: $tablet) {
        &.text-normal {
            font-size: $small;
        }
    }

    @media (max-width: $phone) {
        &.text-small-plus {
            font-size: $small;
        }

        &.text-regular {
            font-size: $small;
        }

        &.text-normal {
            font-size: $small;
        }

        &.text-medium {
            font-size: $small;
        }

        &.text-huge {
            font-size: $largeplusplus;
        }

        &.text-extra-huge {
            font-size: $extralargeplus;
        }

        &.text-extra-huge-plus {
            font-size: $hugeplus;
        }

        &.text-extra-extra-huge {
            font-size: $extrahugeplus;
        }
    }
}

.text-red {
    color: $red !important;
}

.text-blue {
    color: $blue !important;
}

.text-green {
    color: $green !important;
}

.text-red {
    color: $red !important;
}

.text-cx-purple {
    color: $cx-purple !important;
}

.text-purple {
    color: $purple-text !important;
}

.text-success-green {
    color: $success-green !important;
}

.text-darkergray {
    color: $darkergray !important;
}

.text-midgray {
    color: $midgray !important;
}

.text-input-placeholder {
    color: $input-placeholder !important;
}

.text-jetgray {
    color: $jetgray !important;
}

.text-medium-gray {
    color: $medium-gray !important;
}

.text-medium-black {
    color: $mediumblack !important;
}

.text-offblack {
    color: $offblack !important;
}

.text-lilac-action {
    color: $lilac-action !important;
}

.text-navy-blue {
    color: $navy-blue !important;
}

.text-light-black {
    color: $light-black !important;
}

.text-yellow {
    color: $tag-yellow !important;
}

.text-warning {
    color: $dark-mustard !important;
}

.text-semi-black {
    color: $semi-black !important;
}

.text-grey-100 {
    color: var(--cg-grey-100) !important;
}

.text-primary-button-pressed {
    color: $primary-button-pressed !important;
}

.text-truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.divisor-gray {
    color: $divisor-gray !important;
}

.divisor-gray-dark {
    color: $divisor-gray-dark !important;
}

.font-bold {
    font-weight: bold;
}

.font-italic {
    font-style: italic;
}

.font-light {
    font-weight: 300;
}

.font-regular {
    font-weight: 400;
}

.font-medium {
    font-weight: 500;
}

.sub-lh {
    line-height: 16.41px;
}

.title-lh {
    line-height: 33px;
}
