@use "@chicksgroup/styles/mixins" as m;
@use "@chicksgroup/styles/variables" as v;

:root {
    cds-banner{
        --cds-banner-default-background-color: var(--cx-secondary-700);
        --cds-banner-warning-background-color: var(--cg-warning-1000);
        --cds-banner-error-background-color:  var(--cg-error-900);
        --cds-banner-text-color: var(--cx-primary-100);
        --cds-banner-icon-color: var(--cx-primary-100);
        --cds-banner-error-icon-color: var(--cx-primary-100);
        --cds-banner-warning-icon-color: var(--cx-primary-100);
        --cds-banner-height: 32px;
    }

    // Toast
    --cds-toast-width: 420px;
    --cds-toast-min-height: 104px;
    --cds-toast-body-min-height: 70px;
    --cds-toast-shape: 4px;
    --cds-toast-padding-block: 16px;
    --cds-toast-padding-inline: 20px;
    --cds-toast-title-gap: 12px;
    --cds-toast-text-gap: 8px;
    --cds-toast-title-color: #060606;
    --cds-toast-message-color: #060606;
    --cds-toast-success-color: #06af6a;
    --cds-toast-error-color: #ca1717;
    --cds-toast-info-color: #5273ca;

    
    // Large Button

    --cds-large-button-default-background: var(--cg-grey-200);
    --cds-large-button-disabled-background: var(--cg-grey-200);
    --cds-large-button-icon-color: var(--cx-secondary-700);
    --cds-large-button-icon-disabled-color: var(--cg-grey-500);
    --cds-large-button-title-color: var(--cg-grey-900);
    --cds-large-button-title-disabled-color: var(--cg-grey-500);
    --cds-large-button-description-color: var(--cg-grey-600);
    --cds-large-button-description-disabled-color: var(--cg-grey-500);
    --cds-large-button-arrow-color: var(--cg-grey-900);
    --cds-large-button-background-hover-color: var(--cx-primary-600);
    --cds-large-button-background-pressed-color: var(--cx-primary-600);
    --cds-large-button-blend-mode: multiply;
    --cds-large-button-arrow-size: 24px;

    @include m.media(v.$desktop) {
        --cds-large-button-arrow-opacity: 1;
    }
}
