@forward '@chicksgroup/styles/colors';
@forward '@chicksgroup/styles/animations';
@forward '_cx-core-lib';
@use 'styles/cg-grid' as grid; 
@import '~bootstrap/scss/bootstrap';
@import '~glider-js/glider.min.css';
@import 'mdc-styles';
@import 'typography';
@import 'variables';
@import 'colors';
@import 'fonts';
@import 'additional-bootstrap-classes';
@import 'md-overrides';
@import 'flatpickr/dist/flatpickr.css';
@import 'mixins';

a {
    text-decoration: none !important;
    color: $white;
}

body {
    font-family: $primaryfont;
    font-size: $regular !important;
    letter-spacing: 0.68px;
    overflow: hidden;

    &.mdc-dialog-scroll-lock {
        .simplebar-mask {
            z-index: unset;
        }
    }

    @media (max-width: $desktop) {
        overflow: auto;
    }

    @media (width < $tablet) {
        width: 100vw;
        height: 100dvh;
        position: relative;
    }
}

.page-host {
    .simplebar-content {
        height: 100% !important;
    }

    height: 100vh;
    overflow: auto;
    overflow-y: overlay;

    @media (width < $tablet) {
        position: absolute;
        top: 0;
        left: 0;
        @include square(100%);
    }

    &:has(#purchase-flow) {
        ~ .mdc-tooltip {
            margin-top: 3px;

            .mdc-tooltip__surface {
                min-width: 209px;
                max-width: 219px;
            }

            @media (max-width: $phone) {
                max-width: 180px !important;

                .mdc-tooltip__surface {
                    min-width: unset;
                }
            }
        }
    }

    &:has(#profile) {
        ~ .mdc-tooltip {
            margin-top: 3px;

            & .mdc-tooltip__surface {
                min-width: 200px;
            }
        }
    }
}

.router-view-footer {
    background-color: $color-grey-100;
    min-height: 100%;

    &:has(#customer-app) {
        background-color: $color-grey-100;
    }
}

// Default gray ScrollBar

.simplebar-scrollbar::before {
    background-color: rgba(176, 176, 176, 0.5) !important;
    right: 3px !important;
}

.simplebar-scrollbar.simplebar-hover::before {
    background-color: rgba(176, 176, 176, 0.6) !important;
}

.simplebar-dragging .simplebar-scrollbar.simplebar-hover::before {
    background-color: rgba(176, 176, 176, 0.7) !important;
}

// Purple ScrollBar

.purple-scrollbar .simplebar-scrollbar::before {
    background-color: $purple-text !important;
    right: 3px !important;
}

.purple-scrollbar .simplebar-scrollbar.simplebar-hover::before {
    background-color: rgba(49, 15, 162, 0.6) !important;
}

.purple-scrollbar .simplebar-dragging .simplebar-scrollbar.simplebar-hover::before {
    background-color: rgba(49, 15, 162, 0.7) !important;
}

.purple-scrollbar .simplebar-scrollbar.simplebar-visible::before {
    opacity: 1 !important;
}

// Lilac ScrollBar

.lilac-scrollbar .simplebar-scrollbar::before {
    background-color: rgba(87, 98, 213, 1) !important;
    right: 3px !important;
}

.lilac-scrollbar .simplebar-scrollbar.simplebar-hover::before {
    background-color: rgba(87, 98, 213, 0.6) !important;
}

.lilac-scrollbar .simplebar-dragging .simplebar-scrollbar.simplebar-hover::before {
    background-color: rgba(87, 98, 213, 0.7) !important;
}

.simplebar-scrollbar.simplebar-visible::before {
    opacity: 1 !important;
}

.simplebar-track {
    z-index: 9999 !important;
}

.simplebar-offset {
    -webkit-overflow-scrolling: unset !important;
}

section[data-simplebar] {
    max-height: 350px;
    overflow: auto;
    overflow-y: overlay;
    width: 100%;

    @media (max-width: $desktop) {
        max-height: 250px;
    }
}

div[data-simplebar] {
    height: 100vh;
    overflow: auto;
    overflow-y: overlay;

    @media (width < $tablet) {
        height: 100%;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}

.horizontal-divisor {
    height: 4px;
    width: 90px;
    background-color: $cx-purple;
}

.main-content-padding {
    padding-top: 100px;

    @media (max-width: $desktop) {
        padding-top: 60px;
    }

    @media (max-width: $phone) {
        padding-top: 45px;
    }
}

.content-separation {
    margin: 20px 0 20px 0;
}

//This style is applied to avoid blurryness of increase/decrease material icons
.material-icons {
    transform: rotate(0.03deg);

    &.clickable-material-icon {
        cursor: pointer;
    }
}

.link-styling {
    &:hover {
        text-decoration: underline;
    }
}

.mdc-dialog {
    z-index: 1200;
}

.m-display {
    display: none;
}

.dt-display {
    display: block;
}

@media (max-width: $phone) {
    .m-display {
        display: block;
    }

    .dt-display {
        display: none;
    }

    ::-webkit-scrollbar {
        display: none !important;
    }

    .simplebar-scroll-content {
        box-sizing: content-box !important;
    }
}

::-ms-reveal {
    display: none;
}

.arrow-icon {
    color: $cx-purple;
    transition: .3s ease;
}

.switch {
    position: relative;
    display: inline-block;
    @include size(44px, 24px);
}

.switch input {
    opacity: 0;
    @include square(0);
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--Color-Grey-500);
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    $size: 16px;
    @include square($size);
    position: absolute;
    content: "";
    top: 50%;
    right: calc(100% - ($size + 4px));
    transform: translateY(-50%);
    background-color: var(--Color-Grey-100);
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: $purple-text;
}

input:focus + .slider {
    box-shadow: 0 0 1px rgba(67, 0, 169, .54);
}

input:checked + .slider:before {
    right: 4px;
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

:not(.mdc-list-item--disabled).mdc-list-item:hover .mdc-list-item__ripple::before,
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
    opacity: 0;
}

.clickable-icon {
    color: $darkergray-default;

    &:hover {
        color: $darkergray;
    }

    &:active {
        color: $purple-text;
    }
}

.default-anchor {
    @include default-anchor();
}

.circle-icon {
    &.error {
        color: $red-error;
    }

    &.check {
        color: $success-green;
    }

    &.pending {
        color: $yellow;
    }
}

.dev-help {
    background-color: rgb(0,113,178);
    left: 20px;
    bottom: 20px;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    transition: all .1s ease-in;

    &:hover {
        background-color: rgba(0, 113, 178, 0.56);
        scale: 1.1;
    }

    @media (width <= $desktop) {
        bottom: 90px;
    }
}

.help-container {
    background-color: $white;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 15px;
    left: 20px;
    bottom: 95px;
    max-width: 330px;
    height: auto !important;
    flex-wrap: wrap;

    @media (width <= $desktop) {
        bottom: 160px;
    }
}

.help-option {
    transition: all .1s ease-in;
    &:hover {
        color: $purple !important;
    }
}

.dev-copied {
    width: 100px;
    height: auto;
    background-color: $cx-purple;
    color: $white;
    font-weight: 700;
    padding: 10px;
    border-radius: 6px;
    margin: 10px 0px;
}

.first-input {
    border-radius: 12px 0 0 12px;
}

.middle-input {
    border-radius: 0;
}

.last-input {
    border-radius: 0 12px 12px 0;
}

.solo-input {
    border-radius: 12px;
}

.noscroll {
    overflow: hidden !important;
}

.arrow-position-below {
    margin-top: -5px;

    &::after {
        top: 100% !important;
        transform: rotate(180deg) !important;
    }
}

.mdc-expandable--closed .mdc-expandable__content-container {
    height: 0 !important;
    overflow: hidden !important;
}

mdc-select {
    &.mdc-select--activated .mdc-select__dropdown-icon .material-icons {
        transform: rotate(180deg) translateX(15%) translateY(-15%) !important;
    }

    .material-icons {
        transform: rotate(0deg) !important;
    }
}

.primary-disabled-button,
.primary-disabled-button:hover {
    background: linear-gradient(90.12deg, rgba(67, 0, 169, 0.3) 2.45%, rgba(0, 51, 140, 0.3) 101.9%);
    color: $color-grey-100 !important;
}

.primary-dropdown {
    --dropdown-height: 200px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
    background: $white;
    height: var(--dropdown-height) !important;
    border-radius: 4px;

    .simplebar-content-wrapper {
        padding-top: $regularPad !important;
        padding-right: $smallPad !important;
        padding-left: 29px !important;
    }

    .simplebar-horizontal {
        display: none !important;
    }

    .simplebar-scrollbar::before {
        background-color: $purple-text !important;
    }

    .dropdown-option {
        cursor: pointer;
        letter-spacing: 0.001em;
        text-align: left;
        font: 400 #{$regular}/16.41px $primaryfont;
        color: $input-placeholder;
        margin-bottom: 15px;

        &:hover {
            color: $purple-text;
        }
    }
}

.hidden {
    height: 0 !important;

    &__bounce {
        margin-top: -10px !important;
    }
}

$align: (
    "start": flex-start,
    "end" : flex-end,
    "center": center,
    "baseline": baseline,
    "stretch": stretch
);

$justify: (
    "start": flex-start,
    "end" : flex-end,
    "center": center,
    "between" : space-between,
    "around": space-around
);


@each $key, $value in $align {
    @each $key2, $value2 in $justify {
        .flex-#{$key}-#{$key2} {
            @include flex($value, $value2);
        }
    }
}

.cx-notice-box {
    background-color: $secondarycolor-half;
    border-left: 3px solid $cx-purple;
    border-radius: 4px;
    padding: 20px
}

.cx-notice-box a {
    color: #39e29d;
    cursor: pointer
}

.cx-notice-box a:hover {
    text-decoration: underline
}

.cx-notice-box p {
    font-family: Roboto,sans-serif;
    letter-spacing: .014px;
    line-height: 16px
}

.font-size-inherit {
    font-size: inherit;
}

.page-host {
    @include flex-column();
}

.page-host-content {
    flex: 1;
    @include flex(stretch, center);

    > * {
        width: 100%;
    }
}

.flex-center {
    @include flex-center();
}

#intercom-facade-btn {
    background-color: $honolulu-blue !important;
}

.p-regular {
    padding: $regularPad !important;
}

.mt-big {
    margin-top: $bigPad;
}

.mb-regular {
    margin-bottom: $regularPad !important;
}

.mb-big {
    margin-bottom: $bigPad !important;
}

.mt-regular {
    margin-top: $regularPad;
}

.ml-regular {
    margin-left: $regularPad;
}

.mt-small {
    margin-top: $smallPad !important;
}

.mt-small-plus {
    margin-top: $smallPadPlus;
}

.mt-big-pad {
    margin-top: $bigPad !important;
}

.p-regular {
    padding: $regularPad !important;
}

.gap-regular {
    gap: $regularPad;
}

.gap-big {
    gap: $bigPad;
}

.gap-small {
    gap: $smallPad;
}

::root {
    --skeletonWidth: auto;
    --skeletonHeight: auto;
}

@keyframes skeletonFrames {
    0% {
      opacity: 0.2;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.2;
    }
}

.skeleton-loading {
    @include skeleton-background();
    animation: skeletonFrames 2s linear infinite;
    border-radius: 4px;

    * {
        visibility: hidden !important;
    }
}

.skeletonWidth {
    width: var(--skeletonWidth) !important;
}

.skeletonHeight {
    height: var(--skeletonHeight) !important;
}

.hidden-skeleton {
    display: none !important;
}

.tooltip-icon {
    font-family: $materialfontOutlined;
    --md-icon-font: $materialfontOutlined;
    font-size: $medium;
    --md-icon-size: $medium;
    color: rgba(184, 229, 242, 1);
}

.tooltip-icon:hover {
    font-family: $materialfont;
    --md-icon-font: $materialfont;
}

@media (prefers-color-scheme: dark) {
    .tooltip-icon {
        color: rgba(252, 252, 252, 1);
    }
}

@media (prefers-color-scheme: light) {
    .tooltip-icon {
        color: rgba(184, 229, 242, 1);
    }
}

.vertical-align-sub {
    vertical-align: sub;
}

.regular-border {
    @include regular-border();
}

.regular-border-static {
    @include regular-border(false);
}

.cg-grid {
    @include grid.cg-grid($include-resolutions: true);
}

@for $i from 1 through 5 {
    .truncate-text-#{$i} {
        @include truncate-text($i);
    }

    .truncate-text-#{$i}-b {
        @include truncate-text($i, true);
    }
}

.profile-subtitle {
    @extend .profile-subtitle-extend;
}

mdc-icon {
    cursor: default;
}

:root {
    --md-dialog-headline-color: #383838;
    --md-dialog-container-color: #FCFCFC;
    --md-dialog-container-shape: 8px;
    --bs-body-bg: var(--Color-Grey-100);
    --bs-border-color-translucent: var(--Color-Grey-400)
}

.cds-large-button--benefit {
    --cds-large-button-description-color: var(--Color-Grey-900);
    --cds-large-button-box-min-height: 90px;
    --cds-large-button-box-height: 100%;
    --cds-large-button-truncate-lines: 0;
    --cds-large-button-truncate-word-break: normal;
    --cds-option-box-outline: #{$regular-border};
    --cds-option-box-outline-offset: -1px;
    --cds-large-button-default-background: transparent;
}

.cx-google-pay-box {
    height: 50px;

    button {
        border: none !important;
        color: $white !important;
        background: $navbar-text-purple !important;
        font-family: $primaryfont !important;
        border-radius: 4px !important;
        font-size: $regular !important;
        max-width: 254px !important;
        letter-spacing: .15px !important;
        will-change: transform, opacity !important;
        min-width: unset !important;
        padding: 0 4.5rem 0 4.5rem !important;
        outline: unset !important;
        font-weight: 500 !important;

        @media (width <= $widescreen) {
            max-width: unset !important;
        }
    }
}

/*
    Apple Pay Button
*/
apple-pay-button {
    --apple-pay-button-width: 254px;
    --apple-pay-button-height: 50px;
    --apple-pay-button-border-radius: 4px;
    --apple-pay-button-padding: 8px 0px;
    --apple-pay-button-box-sizing: border-box;
    max-width: 254px;
    width: 100%;
    min-width: unset !important;

    @media (width <= $widescreen) {
        max-width: unset !important;
    }
}

.disabled-gpay-button button,
.disabled-apple-pay-button apple-pay-button {
    opacity: .2;
    pointer-events: none;
}

.bs-checkout-area {
    margin-bottom: 20px;
}
