@use '@material/button';
@use '@material/linear-progress';
@use '@material/textfield';
@use '@material/select';
@use '@material/checkbox';
@use '@material/textfield/icon';
@use '@material/radio';
@use '@material/slider';
@use '@material/tooltip';
@use '@material/dialog';
@use '@material/dialog/mdc-dialog';
@use "@material/menu-surface/mdc-menu-surface";
@use '@material/menu/mdc-menu';
@use '@material/menu';
@use '@material/list/mdc-list';
@import 'variables';

$offwhite: rgba(255, 255, 255, 0.6);
$offgreen: rgba(57, 226, 157, 0.7);
$offred: rgba(253, 74, 74, 0.75);
$offgreen-disabled: rgba(57, 226, 157, 0.2);
$offred-disabled: rgba(253, 74, 74, 0.2);
$offblue-disabled: rgba(87, 98, 213, .2);
$hoverblue: rgba(74, 83, 181, 1);
$lightgray: rgba(76, 96, 133, 0.2);

body {
    --mdc-theme-primary: rgba(87, 98, 213, 1);
    --mdc-theme-secondary: rgba(76, 96, 133, 1);
    --mdc-theme-on-primary: $primarycolor;
    --mdc-typography-font-family: $primaryfont;
    --mdc-background-primary: rgba(0, 0, 0, 0);
}

.mdc-select {
    .mdc-menu-surface {
        margin-top: 1px;
    }
}

.mdc-menu-surface {
    overflow: hidden;
}

:not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple:before {
    background-color: unset;
}

.mdc-list {
    padding: 0;
}

.mdc-list-item__primary-text {
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    letter-spacing: .68px;
}

.mdc-list-item--selected .mdc-list-item__primary-text {
    color: rgba(255, 255, 255, 1);
}

.mdc-button {
    height: 52px;
}

.mdc-button--touch {
    margin-top: 0;
    margin-bottom: 0;
}

.mdc-touch-target-wrapper {
    .mdc-button {
        min-width: unset;
        padding: unset;
        font-weight: inherit;
        letter-spacing: inherit;
    }

    .mdc-button--touch {
        margin-top: 0;
        margin-bottom: 0;
        height: initial;
    }
}

.mdc-text-field--filled {
    height: inherit;
}

.mdc-select__anchor {
    border-radius: 4px;
}

.mdc-select--outlined .mdc-select__anchor {
    height: 50px;
}

.mdc-select--filled .mdc-select__anchor {
    height: 50px;
}

.mdc-text-field--outlined:not(.mdc-text-field--textarea) {
    height: 50px;
}

.mdc-select .mdc-floating-label {
    top: 55%;
}

.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
    border-color: unset !important;
}

.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing,
.mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--focused .mdc-notched-outline__trailing {
    border-width: 1px !important;
}

.mdc-floating-label--required::after {
    content: "";
}

.mdc-floating-label:not(.using-label) + .using-label {
    font-size: 16px !important;
    top: 50%;
    transform: translateY(-50%) !important;
}

.mdc-button {
    text-transform: unset;
}

.mdc-select--outlined.mdc-select--disabled .mdc-notched-outline__notch,
.mdc-select--outlined.mdc-select--disabled .mdc-notched-outline__trailing {
    border: 0;
}

.input-profile {
    @include textfield.fill-color($inputgray);
    @include textfield.ink-color($black);
    @include textfield.outline-color($transparent);
    @include textfield.focused-outline-color($transparent);
    @include textfield.hover-outline-color($transparent);
    @include icon.trailing-icon-color($lightgray);
    @include textfield.label-color($white);
    @include textfield.disabled-ink-color(rgba(114, 118, 125, 0.75));
    @include textfield.disabled-fill-color(rgba(76, 96, 133, 0.2));
    @include textfield.disabled-label-color($medium-dark-gray);
    @include textfield.disabled-prefix-color($medium-dark-gray);
    @include textfield.disabled-suffix-color($medium-dark-gray);
    @include textfield.disabled-outline-color($transparent);
    @include textfield.disabled-bottom-line-color(transparent);
    @include textfield.placeholder-color($input-placeholder);
    @include textfield.prefix-color($white);
    @include textfield.suffix-color($white);
    @include textfield.caret-color(rgba(87, 98, 213, 1));
    height: 35px !important;
    font-size: $small !important;
    border-radius: 12px;
    border-top: 2px solid $success-green;

    @media (max-width: $phone) {
        border-radius: 6px;
    }
}

.input-ticket-title {
    @include textfield.ink-color($black);
    @include textfield.fill-color($transparent);
    @include textfield.disabled-outline-color($transparent);
    @include textfield.disabled-bottom-line-color($transparent);
    @include textfield.disabled-outline-color($transparent);
    @include textfield.outline-color($transparent);
    @include textfield.focused-outline-color($transparent);
    @include textfield.hover-outline-color($transparent);
    @include textfield.caret-color($purple-text);
}

.input-password {
    @include textfield.fill-color($palegray);
    @include textfield.ink-color($black);
    @include textfield.outline-color($transparent);
    @include textfield.focused-outline-color($transparent);
    @include textfield.hover-outline-color($transparent);
    @include icon.trailing-icon-color($lightgray);
    @include textfield.label-color($white);
    @include textfield.disabled-ink-color(rgba(114, 118, 125, 0.75));
    @include textfield.disabled-fill-color($palegray);
    @include textfield.disabled-label-color($medium-dark-gray);
    @include textfield.disabled-prefix-color($medium-dark-gray);
    @include textfield.disabled-suffix-color($medium-dark-gray);
    @include textfield.disabled-outline-color($transparent);
    @include textfield.disabled-bottom-line-color(transparent);
    @include textfield.placeholder-color($input-placeholder);
    @include textfield.prefix-color($white);
    @include textfield.suffix-color($white);
    @include textfield.caret-color(rgba(87, 98, 213, 1));
    border: 0.5px;
    border-radius: 4px;
}

.input-password_warning {
    @include textfield.disabled-ink-color(rgba(227, 171, 8, 1));
}

.input-otp {
    @include textfield.fill-color($inputgray);
    @include textfield.ink-color($mediumblack);
    @include textfield.focused-outline-color($cx-purple);
    @include textfield.outline-color(rgba(213, 213, 213, 1));
    @include textfield.bottom-line-color($lilac);
    @include textfield.hover-bottom-line-color($lilac);
    @include textfield.disabled-outline-color($transparent);
    @include textfield.disabled-bottom-line-color(transparent);
    @include textfield.placeholder-color($input-placeholder);
    @include textfield.prefix-color($white);
    @include textfield.suffix-color($white);
    @include textfield.caret-color($lilac);
    border-radius: 4px;
    height: 60px !important;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance:textfield;
    }

    .mdc-text-field__input {
        font-size: $extralarge !important;
        font-weight: 700 !important;
        width: 18px !important;
    }

    @media (max-width: $phone) {
        height: 53px !important;

        .mdc-text-field__input {
            font-size: $largeplus !important;
            width: 10px !important;
        }
    }
}

.mdc-button-blue-filled {
    @include button.filled-accessible($purple-text);
    @include button.ink-color($color-grey-100);
    @include button.disabled-container-fill-color($purple-text);
    @include button.disabled-ink-color($color-grey-100);
    border-radius: 4px;
    border: none;
    appearance: none;
    letter-spacing: 0.15px;
    text-align: center;
    -webkit-appearance: none;
}

.button-blue-filled {
    @include button.filled-accessible($hoverblue);
    @include button.ink-color($white);
    @include button.disabled-container-fill-color($hoverblue);
    @include button.disabled-ink-color($white);
    border-radius: 4px;
    border: none;
    appearance: none;
    letter-spacing: 0.15px;
    text-align: center;
    -webkit-appearance: none;
}

.button-blue-filled:hover {
    @include button.filled-accessible($hoverblue);
}

.button-blue-filled:active {
    @include button.filled-accessible($dark-blue);
}

.button-blue-filled:disabled {
    opacity: 0.2;
}

.input-otp__error {
    @include textfield.fill-color($inputgray);
    @include textfield.ink-color($mediumblack);
    @include textfield.focused-outline-color($cx-purple);
    @include textfield.outline-color($off-red);
    @include textfield.bottom-line-color($red);
    @include textfield.hover-bottom-line-color($red);
    @include textfield.disabled-outline-color($transparent);
    @include textfield.disabled-bottom-line-color(transparent);
    @include textfield.placeholder-color($input-placeholder);
    @include textfield.prefix-color($white);
    @include textfield.suffix-color($white);
    @include textfield.caret-color($lilac);
    border-radius: 4px;
    height: 60px !important;
}

.input-otp__success {
    @include textfield.fill-color($inputgray);
    @include textfield.ink-color($mediumblack);
    @include textfield.focused-outline-color($cx-purple);
    @include textfield.outline-color($green);
    @include textfield.bottom-line-color($success-green);
    @include textfield.hover-bottom-line-color($success-green);
    @include textfield.disabled-outline-color($transparent);
    @include textfield.disabled-bottom-line-color(transparent);
    @include textfield.placeholder-color($input-placeholder);
    @include textfield.prefix-color($white);
    @include textfield.suffix-color($white);
    @include textfield.caret-color($lilac);
    border-radius: 4px;
    height: 60px !important;
}

.input-background {
    @include textfield.disabled-fill-color($secondaryborder);
    @include textfield.fill-color($lightgray);
    @include textfield.outline-color($lightgray);
    @include textfield.focused-outline-color(rgba(255, 255, 255, 0.6));
    @include textfield.hover-outline-color(rgba(255, 255, 255, 0.6));
    @include textfield.ink-color($white);
    @include textfield.label-color($white);
    @include textfield.placeholder-color($gray);
    @include textfield.disabled-ink-color($medium-dark-gray);
    @include textfield.disabled-label-color($medium-dark-gray);
    @include textfield.disabled-prefix-color($medium-dark-gray);
    @include textfield.disabled-suffix-color($medium-dark-gray);
    @include textfield.disabled-outline-color(rgba(255, 255, 255, 0.55));
    @include textfield.disabled-bottom-line-color(transparent);
    @include textfield.bottom-line-color(transparent);
    @include textfield.line-ripple-color(transparent);
    @include textfield.hover-bottom-line-color(transparent);
    @include textfield.disabled-placeholder-color(rgba(255, 255, 255, 0.3));
    @include textfield.suffix-color($white);
    @include textfield.caret-color(rgba(87, 98, 213, 1));
    border-radius: 4px;
}

.input-search {
    @include textfield.fill-color($white);
    @include textfield.outline-color($transparent);
    @include textfield.focused-outline-color($transparent);
    @include textfield.hover-outline-color($transparent);
    @include textfield.ink-color($black);
    @include textfield.placeholder-color($input-placeholder);
    @include textfield.bottom-line-color($transparent);
    @include textfield.line-ripple-color($transparent);
    @include textfield.hover-bottom-line-color($transparent);
    border-radius: 12px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08)
}

.purchase_auth_input {
    @include textfield.fill-color($inputgray);
    @include textfield.ink-color($black);
    @include textfield.outline-color($transparent);
    @include textfield.focused-outline-color($transparent);
    @include textfield.hover-outline-color($transparent);
    @include icon.trailing-icon-color($lightgray);
    @include textfield.label-color($white);
    @include textfield.disabled-ink-color(rgba(114, 118, 125, 0.75));
    @include textfield.disabled-fill-color($palegray);
    @include textfield.disabled-label-color($medium-dark-gray);
    @include textfield.disabled-prefix-color($medium-dark-gray);
    @include textfield.disabled-suffix-color($medium-dark-gray);
    @include textfield.disabled-outline-color($transparent);
    @include textfield.disabled-bottom-line-color(transparent);
    @include textfield.placeholder-color($input-placeholder);
    @include textfield.prefix-color($white);
    @include textfield.suffix-color($white);
    @include textfield.caret-color(rgba(87, 98, 213, 1));
    border: 0.5px;
    border-radius: 4px;
}

.button-search {
    background: transparent;
    border: none;
    color: rgba(153, 153, 153, 1);
}

.input-with-label {
    @include textfield.fill-color($inputgray);
    @include textfield.ink-color($black);
    @include textfield.label-color($semi-black);
    @include textfield.ink-color($black);
    @include textfield.disabled-ink-color($black);
    @include textfield.disabled-fill-color($inputgray);
    @include textfield.disabled-label-color($semi-black);
    @include textfield.disabled-prefix-color($black);
    @include textfield.disabled-suffix-color($black);
    @include textfield.disabled-outline-color($transparent);
    @include textfield.disabled-bottom-line-color($transparent);
    @include textfield.placeholder-color($semi-black);
    @include textfield.prefix-color($black);
    @include textfield.suffix-color($black);
    @include textfield.caret-color($purple-text);
    @include textfield.bottom-line-color($transparent);
    @include textfield.line-ripple-color($transparent);
    @include textfield.hover-bottom-line-color($transparent);
    @include icon.trailing-icon-color($lightgray);

    background-color: $inputgray;
    border: 0.5px;
    border-radius: 4px;

    .mdc-text-field__icon--leading {
        margin-inline: 15px 10px;
    }

    &:hover,
    &.mdc-ripple-surface--hover,
    &.mdc-ripple-upgraded--background-focused,
    &:not(.mdc-ripple-upgraded):focus {
        .mdc-text-field__ripple::before {
            opacity: 0;
        }
    }

    .mdc-floating-label {
        font-size: $regular;

        &.mdc-floating-label--float-above {
            font-size: $small;
            transform: translateY(-106%);
        }
    }

    input {
        font-size: $regular;
    }
}

.select-background {
    @include select.container-fill-color($palegray);
    @include select.label-color((
        'default': $black,
        'hover': $black,
        'focus': $black));
    @include select.ink-color($black);
    @include select.dropdown-icon-color(('default': $black, 'hover': $black, 'focus': $black));
    @include select.outline-color((
        'default': $transparent,
        'hover': $transparent,
        'focus': $transparent));
}

.checkbox {
    @include checkbox.container-colors(
        $unmarked-stroke-color: $transparent,
        $unmarked-fill-color: $inputgray,
        $marked-stroke-color: $transparent,
        $marked-fill-color: $cx-purple,
        $generate-keyframes: false);
    @include checkbox.disabled-container-colors($unmarked-fill-color: rgba(39, 44, 57, 1));
    @include checkbox.ink-color($white);
    @include checkbox.focus-indicator-color($transparent);
    @include checkbox.disabled-ink-color($cx-purple);
    border-radius: 4px;

    &:hover {
        .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
            border-color: $purplehover;
            border-width: 1px;
        }
    }

    .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
        border-color: $transparent !important;
        background-color: $cx-purple !important;
    }

    .mdc-checkbox--anim-unchecked-checked .mdc-checkbox__background,
    .mdc-checkbox--anim-checked-unchecked .mdc-checkbox__background,
    .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
    .mdc-checkbox__native-control:enabled:not(:checked)~.mdc-checkbox__background {
        animation-duration: unset;
        animation-timing-function: unset;
    }

    .mdc-checkbox__ripple:after,
    .mdc-checkbox__ripple:before {
        background-color: unset;
    }

    @media (max-width: $desktop) {
        @include checkbox.container-colors($unmarked-stroke-color: $gray,
        $marked-stroke-color: $lightgray,
        $unmarked-fill-color: $white,
        $marked-fill-color: $lightgray);
        @include checkbox.ink-color($green);

        .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
            border-color: $transparent !important;
            background-color: $palegray !important;
        }

        &:hover {
            .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
                border-color: $transparent;
                border-width: 1px;
            }
        }
    }
}

.customer-checkbox {
    width: 24px !important;
    height: 24px !important;

    .mdc-checkbox__background {
        width: 24px !important;
        height: 24px !important;
        border-radius: 4px !important;
    }

    .mdc-checkbox__checkmark{
        padding: 2px !important;
    }

    @include checkbox.container-colors($unmarked-stroke-color: $transparent,
        $marked-stroke-color: $transparent,
        $unmarked-fill-color: $inputgray,
        $marked-fill-color: $cx-purple
    );
    @include checkbox.ink-color($white);
    border-color: $transparent !important;

    .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
        border-color: $transparent !important;
        background-color: $cx-purple !important;
    }
}

.using-label ~ .mdc-select:not(.using-label) .mdc-floating-label {
    font-size: 14px !important;

    @media (max-width: $desktop) {
        font-size: 12px !important;
    }
}

.mdc-dialog {
    @include dialog.container-fill-color($white);
    @include dialog.title-ink-color($black);
    @include dialog.content-ink-color($softblack);
    @include dialog.shape-radius(12px, false);

    .mdc-dialog__title {
        font-size: $extralarge;
        text-align: center;
    }

    .mdc-dialog__content {
        text-align: center;
    }
}

.mdc-tooltip.mdc-tooltip--shown::after {
    opacity: 1;
    border-width: 0 5px 4px 5px;
}

.mdc-tooltip.mdc-tooltip--hide {
    display: none;
}

.mdc-tooltip {
    @include tooltip.fill-color($purple-text);
    @include tooltip.label-ink-color($white);
    border-radius: 4px;
    box-shadow: unset;
    max-width: 290px;

    .mdc-tooltip__surface {
        min-height: 32px;
        font-size: 12px;
        font-weight: 300;
        padding: 10px;
        transform: scale(1) !important;
        text-align: left;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        white-space: normal;
        word-wrap: break-word;
        display: inline-block;
        max-width: none;
        width: max-content;

        &::after {
            content: "";
            height: 8px;
            width: 100%;
            bottom: -1px;
            position: absolute;
            display: block;
            background: $transparent;
            z-index: 2040;
        }
    }

    &.horizontal-end::after {
        left: calc(100% - 8px);
    }

    &--tooltip-width {
        max-width: var(--tooltip-width) !important;
    }

    @media (max-width: $desktop) {
        display: none !important;
    }
}

.mdc-tooltip::after {
    border-color: transparent transparent $purple-text;
    border-style: solid;
    border-width: 5px;
    bottom: 100%;
    content: ' ';
    left: 50%;
    margin-left: -5px;
    position: absolute;
    opacity: 0;
    transition: opacity 75ms cubic-bezier(.4, 0, 1, 1) 0ms;
}

.mdc-select,
.mdc-text-field {
    .mdc-floating-label {
        font-size: $regular;
        transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), font-size 100ms cubic-bezier(0.4, 0, 0.2, 1);

        @media (max-width: $desktop) {
            font-size: $small;
        }

        &--float-above {
            transform: translateY(-106%) scale(1) !important;
            font-size: $small !important;
        }
    }

    &.no-float-label .mdc-floating-label--float-above {
        top: 50%;
        transform: translateY(-50%) !important;
    }
}

.mdc-text-field, .mdc-button {
    &__ripple:after,
    &__ripple:before {
        background-color: unset !important;
    }
}

.cx-form-select:hover:not(.mdc-text-field--focused) {
    @include textfield.fill-color($search-input-background);

    input:-webkit-autofill {
        &:active,
        &:first-line,
        &:focus,
        &:hover {
            box-shadow: inset 0 0 0 1000px rgba(59, 68, 92, 1);
        }
    }

    &.blue-background-container input:-webkit-autofill {
        &:active,
        &:first-line,
        &:focus,
        &:hover {
            box-shadow: inset 0 0 0 1000px rgba(51, 59, 91, 1);
        }
    }
}

.cx-form-select {
    @include textfield.fill-color($search-input-background);
    @include textfield.ink-color($black);
    @include textfield.outline-color($transparent);
    @include textfield.focused-outline-color($transparent);
    @include textfield.hover-outline-color($transparent);
    @include icon.trailing-icon-color($search-input-background);
    @include textfield.label-color($black);
    @include textfield.disabled-ink-color($black);
    @include textfield.disabled-fill-color($search-input-background);
    @include textfield.disabled-label-color($medium-dark-gray);
    @include textfield.disabled-prefix-color($medium-dark-gray);
    @include textfield.disabled-suffix-color($medium-dark-gray);
    @include textfield.disabled-outline-color($transparent);
    @include textfield.disabled-bottom-line-color($transparent);
    @include textfield.bottom-line-color($transparent);
    @include textfield.line-ripple-color($transparent);
    @include textfield.hover-bottom-line-color($transparent);
    @include textfield.prefix-color($black);
    @include textfield.suffix-color($black);
    @include textfield.caret-color($purple-text);
    border: 0.5px;
    border-radius: 4px;

    &.mdc-text-field--focused {
        background-color: $search-input-background;

        input:-webkit-autofill {
            &:active,
            &:first-line,
            &:focus,
            &:hover {
                box-shadow: inset 0 0 0 1000px rgba(66, 77, 106, 1);
            }
        }

        &.blue-background-container input:-webkit-autofill {
            &:active,
            &:first-line,
            &:focus,
            &:hover {
                box-shadow: inset 0 0 0 1000px rgba(59, 72, 105, 1);
            }
        }
    }

    .mdc-text-field__input:not(textarea) {
        overflow: hidden;
        padding-left: 29px;
        padding-right: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &.mdc-text-field--disabled {
        opacity: .3;
    }
}

.cx-form-control {
    border: 0;
    border-radius: 12px;
    background-color: $backgroundcontrol !important;
    @include textfield.focused-outline-color($purple-text);
    @include textfield.hover-outline-color($purplehover);
    @include textfield.outline-color($transparent);
    @include textfield.ink-color($input-placeholder);
    @include textfield.outline-shape-radius(12px);

    @include textfield.bottom-line-color($transparent);
    @include textfield.disabled-bottom-line-color($transparent);
    @include textfield.hover-bottom-line-color($transparent);
    @include textfield.line-ripple-color($transparent);
    @include textfield.caret-color($black-olive);

    &:hover {
        @include textfield.ink-color($input-placeholder-hover);
    }

    &.mdc-text-field--focused {
        @include textfield.ink-color($black);
        box-shadow: none;
    }

    &.cx-form-control--error {
        @include textfield.focused-outline-color($red-error);
        @include textfield.hover-outline-color($red-error-hover);
        @include textfield.outline-color($transparent);
    }

    &.cx-form-control--success {
        @include textfield.focused-outline-color($success-green);
        @include textfield.hover-outline-color($success-green-hover);
        @include textfield.outline-color($transparent);
    }

    &.cx-form-control--warning {
        @include textfield.focused-outline-color($yellow);
        @include textfield.hover-outline-color($yellow-hover);
        @include textfield.outline-color($transparent);
    }

    &.mdc-text-field--disabled {
        &:hover {
            outline: 1px solid $purplehover;
        }
    }

    &.cx-form-control--warning {
        &.mdc-text-field--disabled {
            &:hover {
                outline: 1px solid $yellow-hover;
            }

            &.mdc-ripple-upgraded--foreground-activation {
                outline: 1px solid $yellow;
            }
        }
    }

    &.cx-form-control--success {
        &.mdc-text-field--disabled {
            &:hover {
                outline: 1px solid $success-green-hover;
            }
        }
    }

    &.cx-form-control--error {
        &.mdc-text-field--disabled {
            &:hover {
                outline: 1px solid $red-error-hover;
            }
        }
    }

    input:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $inputgray inset;
        -webkit-box-shadow: 0 0 0px 1000px $inputgray inset;
    }
}

.arrow-position-below {
    --tooltip-width: 350px;

    &__start{
        max-width: var(--tooltip-width) !important;

        &::after {
            left: 3% !important;
        }
    }
}

.select-background-outlined {
    @include select.outline-color(('default': $transparent, 'hover': $transparent, 'focus': $transparent));

    .mdc-ripple-surface:hover::before,
    .mdc-ripple-surface:hover .mdc-list-item__ripple::before {
        opacity: 0.1;
    }

    .mdc-ripple-upgraded.mdc-ripple-upgraded--background-focused::before {
        opacity: 0.2;
    }

    //Lookup element
    @include textfield.outline-color($transparent);
    @include textfield.focused-outline-color($transparent);
    @include textfield.hover-outline-color($transparent);
    @include textfield.disabled-fill-color($primaryborder);
    @include textfield.disabled-outline-color($transparent);
    @include textfield.disabled-bottom-line-color($transparent);
    @include textfield.bottom-line-color($transparent);
    @include textfield.line-ripple-color($transparent);
    @include textfield.hover-bottom-line-color($transparent);
    border: 0.5px;
    border-radius: 4px;

    &.mdc-text-field--disabled {
        opacity: .3;
    }
}
